<template>
  <div class="">
    <div class="user-flow__logo">
      <img
        src="~@/assets/excelerate-logo.png"
        alt="Excelerate"
        title="Excelerate"
      />
    </div>
    <div class="user-flow" v-if="isLoaded">
      <div class="user-splash"></div>
      <div class="row">
        <div class="col-12 user-form">
          <form @submit.prevent="updateUserDetails" v-if="step === 1">
            <h2 v-if="userDetails.role === 'Professional'">
              WELCOME TO EXCELERATE
            </h2>
            <h4
              class="user-steps text-center"
              v-if="userDetails.role === 'Professional'"
            >
              STEP 2 OUT OF {{ maxProfessionalSteps }}
            </h4>
            <p class="text-center" v-if="userDetails.role === 'Professional'">
              Tell us a bit more about yourself to get started on Excelerate
            </p>
            <div class="form-group" v-if="userDetails.role === 'Professional'">
              <label for="">First name *</label>
              <input
                type="text"
                class="form-control form-control-lg"
                v-model="userDetails.firstName"
                placeholder="Enter your first name"
                autofocus
                required
              />
            </div>
            <div class="form-group" v-if="userDetails.role === 'Professional'">
              <label for="">Last name *</label>
              <input
                type="text"
                class="form-control form-control-lg"
                v-model="userDetails.lastName"
                placeholder="Enter your last name"
                required
              />
            </div>

            <div>
              <div
                class="form-group"
                v-if="userDetails.role === 'Professional'"
              >
                <label for="">Phone number<span>*</span></label>
                <input
                  type="phone"
                  class="form-control form-control-lg"
                  v-model="userDetails.phoneNumber"
                  :required="userDetails.role === 'Professional'"
                  placeholder="Enter your phone number"
                />
              </div>
            </div>

            <div v-if="userDetails.role === 'Professional'">
              <div class="form-group">
                <label for="">Company</label>
                <input
                  type="text"
                  v-model="userDetails.company"
                  class="form-control form-control-lg"
                  placeholder="Select your company or add new"
                  required
                />
              </div>
            </div>

            <div class="form-group" v-if="userDetails.role === 'Professional'">
              <label for=""
                >What is your current position in the company? *</label
              >
              <select
                v-model="userDetails.designation"
                class="form-control"
                :required="userDetails.role === 'Professional'"
              >
                <option value="" selected disabled>Select from list</option>
                <option
                  v-for="(role, index) in roles"
                  :key="index"
                  :value="role"
                >
                  {{ role }}
                </option>
              </select>
            </div>

            <div class="form-group" v-if="userDetails.role === 'Professional'">
              <div class="custom-control custom-checkbox mb-1">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  name="role"
                  id="signupTerms"
                  v-model="terms"
                  value="terms"
                  required
                />
                <label class="custom-control-label" for="signupTerms"
                  >I accept
                  <router-link to="/user/terms" class="color-third"
                    >the terms and conditions</router-link
                  ></label
                >
              </div>
            </div>

            <div class="user-form-buttons">
              <!-- <span class="user-steps" v-if="userDetails.role === 'Professional'">Step 1 OUT OF {{ maxStep-1 }}</span>
                <span class="user-steps" v-if="userDetails.role === 'Student'">Step 1 OUT OF {{ maxStep }}</span>
                <button class="btn btn-primary ml-2">Next</button> -->

              <span
                class="user-steps"
                v-if="userDetails.role === 'Professional' && false"
                >STEP 1 OUT OF {{ maxStep }}</span
              >
              <span class="user-steps" v-if="userDetails.role === 'Student'"
                >STEP 1 OUT OF {{ maxStep }}</span
              >
              <span>{{ failCode }}</span>
              <!-- <span class="btn btn-secondary" type="button" @click="previousStep()">Previous</span> -->
              <button
                class="btn btn-primary ml-2"
                v-if="userDetails.role === 'Student'"
              >
                Next
              </button>
              <!-- <buttn class="btn btn-primary ml-2">Next</buttn> -->
              <button
                class="btn btn-primary ml-2"
                v-if="userDetails.role === 'Professional'"
              >
                Next
              </button>
            </div>
          </form>
          <!-- <div class="widget-footer-note">
              Already have a user? Sign in
              <router-link to="/signin" class="color-first">here</router-link>
              <br />
              Not a Professional? Sign Up As
              <router-link to="/student-signup" class="color-first"
                >Student</router-link
              >
            </div> -->
          <!-- <form action="" v-if="this.step === 3"> -->
          <form v-if="this.step === 3" class="text-center">
            <h1 class="text-uppercase common-text">CONGRATULATIONS</h1>
            <h4 class="welcome-desc font-weight-normal">
              YOUR PROFILE HAS BEEN CREATED
            </h4>
            <i class="fa fa-check check-icon" aria-hidden="true"></i>
            <p class="">
              Our account team will contact you shortly to discuss the next
              steps to get your company closer to talents. <br />
              <br />
              Login is disabled for now.
            </p>
            <button disabled class="btn btn-dark w-200">Login</button>
          </form>
          <template v-if="step === 2 && userDetails.role === 'Professional'">
            <Loader
              :times="2"
              v-if="!jobsData"
              :styleData="{ marginTop: 2 + 'rem' }"
            ></Loader>
            <form @submit.prevent="verifyCode" v-else>
              <h2 class="text-center">VERIFY YOUR PROFILE</h2>
              <h4
                class="user-steps text-center"
                v-if="userDetails.role === 'Professional'"
              >
                STEP 3 OUT OF {{ maxProfessionalSteps }}
              </h4>
              <p class="text-center">
                We have sent a 6-digit code to your email.
                <br />
                Remember to check you spam folder
              </p>
              <div class="form-group text-left mb-0">
                <label>Activate your profile</label>
                <input
                  type="text"
                  v-model="verificationCode"
                  name="code"
                  class="form-control form-control-lg"
                  placeholder="Enter 6-digit code"
                  autofocus
                />
                <span class="text-error">{{ failCode }}</span>
                <div
                  class="alert alert-success mt-3"
                  v-if="verifyMessageSuccess"
                >
                  {{ verifyMessageSuccess }}
                </div>
                <div class="alert alert-danger mt-3" v-if="verifyMessageError">
                  {{ verifyMessageError }}
                </div>
              </div>
              <div class="text-right">
                <button class="btn btn-link" @click.prevent="resendVerificationEmail()">
                  Resend code
                </button>
              </div>
              <div class="user-form-buttons">
                <button
                  class="btn btn-primary ml-2"
                  v-if="userDetails.role === 'Professional'"
                >
                  Complete sign up
                </button>
              </div>
            </form>
          </template>

          <form
            @submit.prevent="updateUserDetails"
            v-if="step === 2 && userDetails.role === 'Student'"
          >
            <h4>Setup your student profile</h4>

            <img
              v-if="userDetails.institution"
              :src="
                '/assets/institutions/' + userDetails.institution.id + '.png'
              "
              class="img-fluid"
              :title="userDetails.institution.name"
            />
            <!-- <div class="form-group">
              <label>Country *</label>
              <select v-model="userDetails.country" class="form-control" @change="reset('country')" required>
                <option v-for="(country, index) in filterByCountries" :key="index">{{country.country}}</option>
              </select> 
            </div> -->

            <div
              class="user-form-wizard"
              :class="userDetails.area ? '' : 'guide'"
            >
              <!-- v-if="user.country && universities.universities" removed this validation -->
              <div class="form-group" @change="reset('institution')">
                <label>Institution *</label>
                <select
                  class="form-control"
                  v-model="userDetails.institution"
                  @change="getCurrentUniversityData()"
                  required
                >
                  <option
                    value=""
                    v-if="universities.universities.length !== 1"
                  >
                    Choose one
                  </option>
                  <option
                    v-for="(institute, index) in universities.universities"
                    :key="index"
                    :value="{
                      uid: institute.id,
                      id: institute.university_id,
                      name: institute.university_name,
                    }"
                  >
                    {{ institute.university_name }}
                  </option>
                </select>
              </div>
              <!-- <div class="form-group" v-if="userDetails.institution && typeof filterFacultyByInstitution !== typeof undefined">
                <label>Choose faculty *</label>
                <select class="form-control" v-model="userDetails.faculty" @change="reset('faculty')" required>
                    <option value="" v-if="filterFacultyByInstitution.length !== 1">Choose one</option>
                    <option v-for="(faculty, index) in filterFacultyByInstitution" :key="index" :value="faculty.name">
                      {{ faculty.name }}
                    </option>
                </select>
              </div> -->
              <div class="form-group" v-if="userDetails.institution">
                <label>Degree *</label>
                <select
                  class="form-control"
                  v-model="userDetails.degree"
                  @change="reset('degree')"
                  required
                >
                  <option value="" v-if="universityData.degrees.length !== 1">
                    Choose one
                  </option>
                  <option
                    v-for="(degree, index) in universityData.degrees"
                    :key="index"
                    :value="{
                      degree: degree.degree_name,
                      degree_id: degree.degree_id,
                    }"
                  >
                    {{ degree.degree_name }}
                  </option>
                </select>
              </div>
              <div class="form-group" v-if="userDetails.degree">
                <label>Area *</label>
                <!-- <select class="form-control" v-model="userDetails.area" required>
                  <option value="" v-if="universityData.programme.length !== 1">Choose one</option>
                    <option v-for="(area, index) in universityData.programme" :key="index" :value="area.programme_name">
                      {{ area.programme_name }}
                    </option>
                </select> -->
                <autocomplete
                  :search="searchArea"
                  required
                  placeholder="Search for an area"
                  aria-label="Search for an area"
                  class="hard-skills"
                  :get-result-value="getResultValue"
                  @submit="setAreaForUser"
                ></autocomplete>
              </div>
              <div
                class="form-group"
                v-if="userDetails.degree && userDetails.institution.uid == 1"
              >
                <label>Area *</label>
                <!-- <select class="form-control" v-model="userDetails.area" required>
                  <option value="" v-if="universityData.programme.length !== 1">Choose one</option>
                    <option v-for="(area, index) in universityData.programme" :key="index" :value="area.programme_name">
                      {{ area.programme_name }}
                    </option>
                </select> -->
                <autocomplete
                  :search="searchArea"
                  required
                  placeholder="Search for an area"
                  aria-label="Search for an area"
                  class="hard-skills"
                  :get-result-value="getResultValue"
                  @submit="setSecondaryAreaForUser"
                ></autocomplete>
              </div>
            </div>

            <hr class="mt-4" />
            <div class="form-group">
              <label>Graduation semester *</label>
              <select
                v-model="userDetails.graduatingSemester"
                class="form-control"
                required
              >
                <option v-for="(semester, index) in semesters" :key="index">
                  {{ semester.value }}
                </option>
              </select>
            </div>

            <div class="text-muted">*Required</div>

            <div class="user-form-buttons">
              <span
                class="user-steps"
                v-if="userDetails.role === 'Professional'"
                >STEP 2 OUT OF {{ maxStep - 1 }}</span
              >
              <span class="user-steps" v-if="userDetails.role === 'Student'"
                >STEP 2 OUT OF {{ maxStep }}</span
              >
              <!-- <span class="btn btn-secondary" type="button" @click="previousStep()">Previous</span> -->
              <button class="btn btn-primary ml-2">Finish</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- <div class="mt-3 mb-5 text-center">
      <router-link
        to="/dashboard/"
        class="btn btn-link"
        v-if="user.status === 'ok'"
        >Back to dashboard</router-link
      >
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
//import _ from 'lodash'
import Autocomplete from '@trevoreyre/autocomplete-vue';
import '@trevoreyre/autocomplete-vue/dist/style.css';
import json_institutions from '@/json/institutions.json';
import json_interests from '@/json/interests.json';
import json_semesters from '@/json/semesters.json';
import { storage } from '../../firebase';
import Loader from '@/components/Loader';
import { HTTP } from '../../axios/axios';

export default {
  data: function () {
    return {
      differentStructure: false,
      step: 1,
      maxStep: 2,
      maxProfessionalSteps: 3,
      // institutions: json_institutions,
      interests: json_interests,
      userDetails: {
        designation: '',
        customProgramme: false,
      },
      verificationCode: '',
      verifyMessageSuccess: '',
      terms: false,
      verifyMessageError: '',
      semesters: json_semesters,
      jobsData: {},
      userCode: '',
      isVerified: false,
      roles: [
        'CEO',
        'CHRO',
        'CPO',
        'Other C-Level',
        'HR Director',
        'HR Manager',
        'HR Specialist',
        'HR Assistant',
        'Recruiter',
        'Manager',
        'Team-Lead',
        'Other',
      ],
      universityData: {},
    };
  },
  metaInfo: {
    title: 'Welcome to Excelerate',
    meta: [
      {
        name: 'description',
        content:
          'A digital platform that connects students and companies via thesis collaboration matching.',
      },
      { property: 'og:title', content: 'Welcome to Excelerate.' },
      {
        property: 'og:description',
        content:
          'A digital platform that connects students and companies via thesis collaboration matching.',
      },
    ],
  },
  components: {
    Loader,
    Autocomplete,
  },
  mounted: function () {
    this.$store.dispatch('getUniversityData');
    this.$store.dispatch('setSignupData', 'Professional');
  },
  // created() {

  // },
  computed: {
    ...mapState(['user']),
    stateCheck: function () {
      var vm = this;
      vm.userCode = vm.user.verificationCode;
      return true;
    },
    failCode: function () {
      return this.$store.getters.failCode;
    },
    isLoaded: function () {
      var vm = this;
      var result = false;
      if (this.user.userId !== null) {
        var userInfo = JSON.parse(JSON.stringify(vm.$store.state.user));

        if (userInfo.role === 'Professional') {
          this.loadJobs();
          userInfo.designation =
            typeof userInfo.designation !== 'undefined'
              ? userInfo.designation
              : '';

          vm.userDetails = userInfo;
        } else {
          vm.userDetails = userInfo;
        }
        result = true;
      }
      return result;
    },
    universities: function () {
      return this.$store.getters.universities;
    },
    filterByCountries: function () {
      var vm = this;
      // var institutionCollection = vm.institutions;
      var institutionCollection = 'hello';
      return institutionCollection;
    },
    filterFacultyByInstitution: function () {
      var vm = this;
      var institutions = [];
      var faculties = [];
      var result = [];
      if (typeof this.filterInstitutionsByCountry !== typeof undefined) {
        this.filterByCountries.forEach(function (country, index) {
          if (country.country === vm.userDetails.country) {
            institutions.push(country);
          }
        });
        institutions[0].institutions.forEach(function (institution, index) {
          if (institution.name === vm.userDetails.institution.name) {
            faculties.push(institution);
          }
        });
        result = faculties[0].faculties;
      }
      if (result.length === 1) {
        vm.userDetails.faculty = result[0].name;
      }
      return result;
    },
    jobListLink: () => {
      return storage
        .refFromURL('gs://excelerate2020.appspot.com/Interestfields.json')
        .getDownloadURL();
    },
  },
  methods: {
    //Jobs
    async loadJobs() {
      let jobListLink = await this.jobListLink;
      fetch(jobListLink, {
        method: 'GET',
      })
        .then((res) => res.json())
        .then((result) => {
          let jobs = [];
          result.forEach((parent) => {
            parent.field_one.forEach((stream) => {
              let ctgsSorted = stream.field_two.sort((a, b) =>
                a.name.localeCompare(b.name)
              );
              ctgsSorted.forEach((ctgs) => {
                let subjobs = [];
                ctgs.field_three.forEach((job) => {
                  subjobs.push({
                    __id: job.id,
                    name: job.name,
                    catgeory: ctgs.id,
                    catgeory_name: ctgs.name,
                    stream: stream.id,
                    parent: parent.id,
                  });
                });
                jobs.push({
                  id: ctgs.id,
                  name: ctgs.name,
                  subjobs: subjobs,
                  description: ctgs.description,
                  parent: stream.name,
                });
              });
            });
          });
          this.jobsData = jobs.sort((a, b) => a.name.localeCompare(b.name));
        });
    },
    previousStep: function () {
      this.step--;
    },
    nextStep: function () {
      this.step++;
    },
    resendVerificationEmail: function () {
      const vm = this;
      const verificationEmailData = {
        toEmail: this.user.email,
        verificationCode: this.user.verificationCode,
      };
      vm.$store
        .dispatch('sendVerificationMail', verificationEmailData)
        .then(() => {
          vm.verifyMessageSuccess = 'Check your inbox';
        })
        .catch(() => (vm.verifyMessageError = 'Something went wrong'));
    },
    //uni selector specific
    reset: function (target) {
      if (target == 'country') {
        this.userDetails.institution = '';
        this.userDetails.faculty = '';
        this.userDetails.degree = '';
        this.userDetails.area = '';
      }
      if (target == 'institution') {
        this.userDetails.faculty = '';
        this.userDetails.degree = '';
        this.userDetails.area = '';
      }
      if (target == 'faculty') {
        this.userDetails.degree = '';
        this.userDetails.area = '';
      }
      if (target == 'degree') {
        this.userDetails.area = '';
      }
    },
    updateUserDetails: function () {
      var vm = this;
      //for students
      if(this.step === 1) {
        this.userDetails.status = 'ok'
      }
      if ((this.userDetails.role === 'Student') & (this.step < this.maxStep)) {
        //if(this.step < this.maxStep) {
        this.$store.dispatch('updateUserDetails', {
          ...this.userDetails,
          payload: 'Student',
          docId: vm.userDetails.userId,
          subscription: false,
          subscriptionPlan: null,
        });
        //this.$store.dispatch('updateUserDetails', this.userDetails);
        this.nextStep();

        //last step
      } else if (
        (this.userDetails.role === 'Student') &
        (this.step === this.maxStep)
      ) {
        if (vm.userDetails.status !== 'ok') {
          vm.userDetails.status = 'ok';
          //MAIL "'Student - New Student User Mail'" @firstName
          const templateData = {
            toEmail: vm.userDetails.email,
            template: 'Student - New Student User Mail',
            firstName: vm.userDetails.firstName,
          };
          this.$store.dispatch('sendMail', templateData);
        }
        this.userDetails.degree = this.userDetails.degree.degree; // dirty but will work for this sprint since we still have firebase
        // console.log("🚀 ~ file: Welcome.vue:459 ~ this.userDetails", this.userDetails)
        this.$store
          .dispatch('updateUserDetails', {
            ...this.userDetails,
            payload: 'Student',
            docId: vm.userDetails.userId,
            subscription: false,
            subscriptionPlan: null,
          })
          .then((response) => {
            vm.$router.push('/dashboard/');
          });
      }

      //for professionals
      if (this.userDetails.role === 'Professional') {
        //console.log(this.userDetails);
        if (this.step === 1) {
          const vm = this;
          const verificationEmailData = {
            toEmail: this.user.email,
            verificationCode: this.user.verificationCode,
          };
          // this.$store.dispatch('sendMail', verificationEmailData)
          vm.$store.dispatch('sendVerificationMail', verificationEmailData);
        }
        if (this.step === 2 && vm.userDetails.status !== 'ok') {
          //MAIL "'Professional - New Company User Mail'" @firstName
          const templateData = {
            toEmail: vm.userDetails.email,
            template: 'Professional - New Company User Mail',
            firstName: vm.userDetails.firstName,
          };
          const templateData1 = {
            toEmail: 'nicolas@excelerate.dk',
            template: 'new professional signup',
            param1: vm.userDetails.firstName,
            param2: vm.userDetails.lastName,
            param3: vm.userDetails.email,
            param: vm.userDetails.phoneNumber,
          };
          this.$store.dispatch('sendMail', templateData);
          this.$store.dispatch('sendMail', templateData1);
          vm.userDetails.status = 'ok';
        }
        this.$store
          .dispatch('updateUserDetails', {
            ...this.userDetails,
            payload: 'Professional',
            docId: vm.userDetails.userId,
            subscription: false,
            subscriptionPlan: null,
          })
          .then((response) => {
            // if(this.step === 2) {
            //   this.$router.push('/dashboard/');
            // }
            // if(this.step !== 2){
            this.nextStep();
            // }
          });
      }
    },
    verifyCode: function () {
      var vm = this;
      this.verifyMessageSuccess = '';
      this.verifyMessageError = '';
      // var userVerificationCode = vm.userDetails.verificationCode; // why was this not updated
      var inputCode = this.verificationCode;
      if (parseInt(vm.userDetails.verificationCode) === parseInt(inputCode)) {
        vm.verifyMessageSuccess = 'Code accepted';
        this.userDetails.status = 'verified'
        vm.updateUserDetails();
        vm.$store.dispatch('updateUserVerification', true);
      } else {
        vm.verifyMessageError =
          'No match. Please check your code and try again.';
      }
    },
    async getCurrentUniversityData() {
      try {
        const res = await HTTP.get(
          `getUniversityDataListing/${this.userDetails.institution.uid}`
        );
        if (res.status == 200) {
          // no idea wtf this data is now. Random naming with random data is what we have here
          this.universityData = res.data.payload;
        }
      } catch (error) {
        console.log('err');
      }
      // this.$store.dispatch('getCurrentUniversityData', )
      // console.log('wqeq', this.userDetails.institution.id)
    },
    searchArea(input) {
      const vm = this;
      this.userDetails.customProgramme = true;
      if (input.length < 1) {
        return [];
      }
      const programmes = this.universityData.programmes.filter((prog) => {
        return prog.degree_id === vm.userDetails.degree.degree_id;
      });
      return programmes.filter((area) => {
        return area.programme_name
          .toLowerCase()
          .startsWith(input.toLowerCase());
      });
    },
    getResultValue(result) {
      this.userDetails.customProgramme = false;
      return result.programme_name;
    },
    setAreaForUser(area) {
      this.userDetails.area = area.programme_name;
    },
    setSecondaryAreaForUser(area) {
      this.userDetails.secondaryArea = area.programme_name;
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.multiselect__option--highlight {
  background: #bd1a77;
  outline: none;
  color: #fff;
}
</style>
<style scoped> 
.check-icon{
  color: #bd1a77;
  font-size: 100px;
  margin: 50px 0;
}
.user-flow__logo{
  padding:40px 0;
  text-align: center;
}
</style>
